import React from 'react';
import styled from '@emotion/styled';
import VertoIcon from '../../images/verto.png';
import {ReactComponent as DocsIcon} from '../../images/docs.svg';

const Wrapper = styled.div({
  display: 'flex',
  fontSize: 24
});

const StyledDocsIcon = styled(DocsIcon)({
  height: '0.7857142857em',
  marginTop: '0.17142857143em'
});

export default function Logo() {
  return (
    <Wrapper>
      {/* having the height in `StyledApolloIcon` gets overridden by a 293px from emotion */}
      <img src={VertoIcon} style={{height: '1em', marginRight: '0.2857142857em'}}/>
      <StyledDocsIcon />
    </Wrapper>
  );
}
